import React from 'react';
import styled, { injectGlobal } from 'react-emotion';
import { fontSize } from 'styled-system';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import Footer from '../Footer';
import colors from '../../utils/colors';


/* eslint-disable */
import normalize from 'normalize.css';
import { isInputEvent } from 'formik';
import { register } from '../../utils/real-api';
injectGlobal`
  @font-face {
    font-family: 'Fredericka the Great';
    src: url(/fonts/FrederickatheGreat-Regular.ttf);
  };
  @font-face {
    font-family: 'Klee One';
    src: url(/fonts/KleeOne-SemiBold.ttf);
  };
  * {
      font-family: 'Klee One', serif;
      box-sizing: border-box;
  };
  body {
    background-color: #d6d9e8;
  }
  a {
    text-decoration: none;
    color: inherit;
  };
  a:hover {
    cursor: pointer;
  };
  h1 {
    font-size: 2.4em;
  }
  h1 {
    font-family: 'Fredericka the Great', cursive;
    padding-top: 20px;
  }
  h1.congratulations {
    padding-top:40px;
    font-size: 1.8em;
  }
  h1.padding {
    padding-top:40px;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${colors.secondary}
  };
  h3.center {
    text-align: center;
    padding-bottom: 10px
  }

  p {
    color: ${colors.accent};
    font-family: 'Klee One', serif;
    line-height: 1.2;
    padding-right: 15px;
    padding-left:15px;
    font-size: 1em;
    @media screen and (max-width: 600px) {
      font-size: 1.5em;
    }
    /* text-align: justify; */
  }
  p.name {
    font-weight: bold;
    font-size: 2.0em
  }
  p.nameInfo {
    font-size: 1.1em
  }
  a.link {
    text-decoration: underline;
    font-weight: bold;
  }
  p.italic {
  font-style: italic;
  padding-bottom: 25px;
  }
  h3.button {
    color: ${colors.primary}
  }

  /* CSS FOR FORMS*/
  /* generic forms*/
  input.form-field {
    width: 100%;
    padding: 7px;
    margin: 10px;
    display: block;

  }

  label.form-field-label {
    display: inline-block;
    width: 100%;
    padding: 7px;
    margin: 10px;
    text-align: right;
  }

  /* Found location of gooseberry forms*/
  label.form-field-label-code {
    width: 40%;
    padding: 7px;
    margin: 10px;
    text-align: right;
    display: block;
    @media screen and (max-width: 600px) {
    text-align: center;
    width: 100%;
    margin-top: 0;
    }
  }

  input.form-field-register {
    width: 60%;
    padding: 7px;
    margin: 10px;
    margin-right:5%;
    @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0;
    margin-right:2%;
    }
  }

  input.form-field-code {
    width: 40%;
    padding: 7px;
    margin: 10px;
    @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0;
    }
  }

  input.form-field-checkbox {
    transform: scale(1.5);
    margin: 12px;
  }

  label.form-field-label-checkbox {
    width: 40%;
    padding: 7px;
    margin: 10px;
    text-align: right;
    display: block;
    @media screen and (max-width: 600px) {
    width: 40%;
    margin-top: 0;
    }
  }

  div.error-message { 
    width: 100%;
    text-align: center;
    color: #943126 ;
    font-size: 0.8em;
  }

  p.error-message { 
    text-align: center;
    color: #943126 ;
    font-size: 0.8em;
  }
`;

/* eslint-enable */

const FontConfig = styled.div`
  ${fontSize};
`;

const Layout = ({ children }) => (
  <FontConfig fontSize={[1, 2, 3]}>
    <Navigation />
    {children}
  </FontConfig>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;
