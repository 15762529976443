const colors = {
  theme: {
    primary: '#f1f1f0',
    secondary: '#243446',
    accent: '#333',
    white: '#FFFFFF'
  }
};

export default colors.theme;
