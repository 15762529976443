const url = '/api/'

export const refresh = () => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'refresh',
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};

export const register = ({ name, password, email, email_publicity, address, postcode, wanted, arrived }) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'update',
      arguments: { name, password, email, email_publicity, address, postcode, wanted, arrived }
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};

export const login = ({ name, password }) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'login',
      arguments: { name, password },
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};

export const found = ({ keyword }) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'found',
      arguments: { keyword },
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};

export const resetrequest = ({ name, email }) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'resetrequest',
      arguments: { name, email },
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};

export const resetpassword = ({ reset_key, password }) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      command: 'resetpassword',
      arguments: { reset_key, password },
    }),
  }).catch(() =>
    Promise.reject('not implemented yet')
  );
};
