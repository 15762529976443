import styled from 'react-emotion';
import backgroundPaper from '../../assets/images/paper-transparent.png';
import {
  space,
  width,
  fontSize,
  color,
  textAlign,
  justifyContent,
  style
} from 'styled-system';

const wrap = style({
  prop: 'wrap',
  cssProperty: 'flexWrap'
});

export const Box = styled.div`
  overflow: hidden;
  ${space} ${width} ${fontSize} ${color} ${textAlign};
`;

export const PaperBox = styled.div`
  background-image: url(${backgroundPaper});
  background-size: 100% 100%;
  overflow: hidden;
  /* border-radius: 20%; */
  ${space} ${width} ${fontSize} ${color} ${textAlign};
`;

export const Flex = styled.div`
  display: flex;
  ${justifyContent} ${wrap};
`;

export const JigsawContainer = styled.div`
  width: 500px;
  height: 332px;
  background-size: contain;
  background-repeat: no-repeat;
`;
